import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDDwrJ_7RIOgu0YCeZe8uZpUkYyhOiyTEA",
    authDomain: "subverse-d7edd.firebaseapp.com",
    projectId: "subverse-d7edd",
    storageBucket: "subverse-d7edd.appspot.com",
    messagingSenderId: "80202639178",
    appId: "1:80202639178:web:b4ac435e1641841801c3b3",
    measurementId: "G-FN7D89RDYS"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {
  db,
  collection,
  addDoc,
  getDocs,
  query
};