import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom";
import GlobeGif from "../assets/globe.gif";
import Logo from "../assets/largelogo.svg";
import { db } from "../firebase";
import { doc, setDoc, collection, query, where, onSnapshot, getDocs, updateDoc, increment, getDoc } from "firebase/firestore"; 
import { connectWallet, getCurrentWalletConnected } from "../scripts/interact.js";

export default function WaitlistLanding (props) {
  const navigate = useNavigate();

  // state variables
  const [address, setAddress] = useState(props.wallet);
  const [leaderboard, setLeaderboard] = useState([]);
  const [XP, setXP] = useState(null);
  const [myRank, setMyRank] = useState(null);
  const [signedUp, setSignedUp] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [forPeople, setForPeople] = useState(true);
  const [isOrg, setIsOrg] = useState(false);
  const [copied, setCopied] = useState("Copy Referral Link")
  const [loading, setLoading] = useState(true);

  // state variables for people waitlist form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telegram, setTelegram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [inputAddress, setInputAddress] = useState(props.wallet);
  const [developer, setDeveloper] = useState(false);
  const [designer, setDesigner] = useState(false);
  const [artist, setArtist] = useState(false);
  const [communityManager, setCommunityManager] = useState(false);
  const [entrepreneur, setEntrepreneur] = useState(false);
  const [investor, setInvestor] = useState(false);
  const [student, setStudent] = useState(false);
  const [journalist, setJournalist] = useState(false);

  // state variables for orgs waitlist form
  const [orgMemberName, setOrgMemberName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgPosition, setOrgPosition] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgTwitter, setOrgTwitter] = useState("");
  const [orgSite, setOrgSite] = useState("");
  const [orgTeam, setOrgTeam] = useState(1);
  const [smallSize, setSmallSize] = useState(false);
  const [mediumSize, setMediumSize] = useState(false);
  const [largeSize, setLargeSize] = useState(false);
  const [scaleSize, setScaleSize] = useState(false);

  useEffect(() => {
    // get leaderboard data
    async function getData() {
      let leaderboardData = [];
      const querySnapshot = await getDocs(collection(db, "leaderboard"));
      querySnapshot.forEach((doc) => {
        leaderboardData.push(doc.data());
      });
      leaderboardData = leaderboardData.sort((a, b) => (a.referrals < b.referrals) ? 1 : -1 )
      const index = leaderboardData.findIndex(object => {
        return object.walletAddress == address;
      });
      setMyRank(index + 1);
      let xpval = leaderboardData[index].referrals * 500;
      setXP(xpval + 500);

      // generate leaderboard jsx
      let board = leaderboardData.map((obj) => {
        let rank = leaderboardData.findIndex((object) => {
          return object.walletAddress === obj.walletAddress;
        });
        if (rank >= 0) {
          // wallet address exists on leaderboard
          setSignedUp(true);
        } else {
          setSignedUp(false);
        }
        return <><div className="LeaderboardText">{rank + 1}</div><div className="LeaderboardText">{obj.name.split(" ")[0]}</div><div className="LeaderboardText">{obj.referrals}</div><div className="LeaderboardText">{(obj.referrals * 500) + 500}</div></>
      });
      setLeaderboard(board);
    }
    try {
      getData();
    } catch (err) {
      console.log(err);
      setSignedUp(false);
    }
    //console.log(signedUp);
    setLoading(false);
    //console.log(signedUp);
  }, [signedUp, address]);

  // update if metamask wallet changes
  useEffect(() => {
    setLoading(true);
    if (props.wallet == "Connect Metamask") {
      setInputAddress("");
      setAddress("");
      setSignedUp(false);
    } else {
      setInputAddress(props.wallet);
      setAddress(props.wallet);
    }
  }, [props.wallet]);

  // handle "join waitlist" button click
  function openWaitlist() {
    setSigningUp(true);
  }

  // handle org size checkbox click
  function handleSizeClick(size) {
    if (size == 'small') {
      setSmallSize(!smallSize);
      setMediumSize(false);
      setLargeSize(false);
      setScaleSize(false);
    } else if (size == 'medium') {
      setMediumSize(!mediumSize);
      setSmallSize(false);
      setLargeSize(false);
      setScaleSize(false);
    } else if (size == 'large') {
      setLargeSize(!largeSize);
      setSmallSize(false);
      setMediumSize(false);
      setScaleSize(false);
    } else if (size == 'scale') {
      setScaleSize(!scaleSize);
      setSmallSize(false);
      setMediumSize(false);
      setLargeSize(false);
    }
  }


  // handle people signup submission
  async function submitPeopleSignup(event) {
    event.preventDefault();
    try {
      await setDoc(doc(db, "leaderboard", inputAddress), {
        name: name,
        walletAddress: inputAddress,
        referrals: 0
      });
      try {
        if (props.referral != "") {
          let snap = await getDoc(doc(db, "leaderboard", props.referral.toLowerCase()));

          if (snap.exists()) {
            if (snap.data().referrals < 1000) {
              await updateDoc(doc(db, "leaderboard", props.referral.toLowerCase()), {
                referrals: increment(1)
              });
              await setDoc(doc(db, "waitlist", props.referral.toLowerCase(), "referrals", inputAddress), {
                walletAddress: inputAddress
              });
            }
          } else {
            await updateDoc(doc(db, "leaderboard", props.referral.toLowerCase()), {
              referrals: increment(1)
            });
            await setDoc(doc(db, "waitlist", props.referral.toLowerCase(), "referrals", inputAddress), {
              walletAddress: inputAddress
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
      await setDoc(doc(db, "waitlist", inputAddress), {
        name: name,
        email: email,
        telegram: telegram,
        twitter: twitter,
        walletAddress: inputAddress,
        developer: developer,
        designer: designer,
        artist: artist,
        communityManager: communityManager,
        entrepreneur: entrepreneur,
        investor: investor,
        student: student,
        journalist: journalist,
        referredBy: props.referral.toLowerCase()
      }).then(() => {

        setAddress(inputAddress);
        setSignedUp(true);
      });
    } catch (err) {
      console.log(err);
      alert('error: this address may already be registered');
    }
  }

  // handle org signup submission
  async function submitOrgSignup(event) {
    event.preventDefault();
    try {
      await setDoc(doc(db, "orgwaitlist", orgName), {
        contactName: orgMemberName,
        contactPosition: orgPosition,
        orgName: orgName,
        contactEmail: orgEmail,
        twitter: orgTwitter,
        url: orgSite,
        teamSize: orgTeam,
        smallCommunity: smallSize,
        mediumCommunity: mediumSize,
        largeCommunity: largeSize,
        scaleCommunity: scaleSize
      }).then(() => {
        setIsOrg(true);
        setSignedUp(true);
      });
    } catch (err) {
      console.log(err);
      alert("error: this orgName may already be registered");
    }
  }

  async function copyReferral() {
    navigator.clipboard.writeText("https://subverse.world/" + address);
    setCopied("Copied!")
    // sleep, then change wallet address back
    await new Promise(r => setTimeout(r, 1500));
    setCopied("Copy Referral Link");
  }

  return (
    loading
    ?
    <div className="HeroContainer"></div>
    :
    (
      signedUp
      ?
      (
        isOrg
        ?
        <div className="HeroContainer">
          <div className="WaitlistHeaderContainer">
            <div className="WaitlistTitle">Join the ~ Movement ~</div>
            <div className="WaitlistSubtitle">We're building the first platform for decentralized work! Join the waitlist to get early access + early ownership.</div>
          </div>
          <div className="Subtitle">Thanks for signing up! We'll be in touch shortly to get you set up on Subverse.</div>
        </div>
        :
        <div className="HeroContainer">
          <div className='ReferralPage'>
            <div className="TopContainer">
              <div className="LeaderboardContainer">
                <div className="LeaderboardHeader">----- Awesome People Leaderboard -----</div>
                <div className="LeaderboardGridContainer">
                  <div className="LeaderboardText">Rank</div>
                  <div className="LeaderboardText">Name</div>
                  <div className="LeaderboardText">Referrals</div>
                  <div className="LeaderboardText">$XP</div>
                  {leaderboard}
                </div>
              </div>
              <div className="StatsContainer">
                <div className="SubSubtitleFlex">Your leaderboard position: {myRank}</div>
                <div className="SubSubtitleFlex">Your claimable $XP: {XP}</div>
                <div className="SubSubtitleFlex">Total $XP supply: 1000000000</div>
                <br />
                <div className="CopyReferral" onClick={copyReferral}>{copied}</div>
              </div>
            </div>
            <br />
            <br />
            <div className="SubtitleFlex">Thanks for signing up! We'll be in touch soon (:</div>
            <div className="SubSubtitleFlex">In the meantime, you can earn more $XP by referring your friends to Subverse! You'll be able to claim your $XP (Subverse governance token) when Subverse launches & your referrals create resumes.</div>
          </div>
        </div>
      )
      :
      (
        signingUp
        ?
        (
          forPeople
          ?
          <div className="HeroContainer">
            <div className="WaitlistHeaderContainer">
              <div className="WaitlistTitle">Join the ~ Movement ~</div>
              <div className="WaitlistSubtitle">We're building the first platform for decentralized work! Join the waitlist to get early access + early ownership.</div>
            </div>
            <div className="ToggleContainer">
              <button className="WaitlistToggleClicked">
                <p className="WaitlistToggleText">For People</p>
              </button>
              <button className="WaitlistToggleUnclicked" onClick={() => setForPeople(false)}>
                <p className="WaitlistToggleText">For Organizations</p>
              </button>
            </div>
            <form className="WaitlistForm" onSubmit={(e) => submitPeopleSignup(e)}>
              <label className="FormLabel">
                What's your name or pseudonym? (so we know what to call you) *
                <input 
                  className="FormInput"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label className="FormLabel">
                What's your email? (we will reach out to you when we launch) *
                <input 
                  className="FormInput"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <label className="FormLabel">
                What's your wallet address? (so we can add you to our beta list) *
                <input 
                  className="FormInput"
                  type="text"
                  value={inputAddress}
                  onChange={(e) => setInputAddress(e.target.value)}
                  required
                  readOnly
                  onClick={connectWallet}
                />
              </label>
              <label className="FormLabel">
                What's your Telegram handle? (so we can help onboard you if needed)
                <input 
                  className="FormInput"
                  type="text"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                />
              </label>
              <label className="FormLabel">
                What's your Twitter handle? (so we can connect in the twitterverse)
                <input 
                  className="FormInput"
                  type="text"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </label>
              <label className="FormLabel">
                Are you any of the following? (check all that apply)
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={developer}
                  value={developer}
                  onChange={(e) => setDeveloper(!developer)}
                />
                Developer
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={designer}
                  value={designer}
                  onChange={(e) => setDesigner(!designer)}
                />
                Designer
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={artist}
                  value={artist}
                  onChange={(e) => setArtist(!artist)}
                />
                Artist
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={communityManager}
                  value={communityManager}
                  onChange={(e) => setCommunityManager(!communityManager)}
                />
                Community Manager
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={entrepreneur}
                  value={entrepreneur}
                  onChange={(e) => setEntrepreneur(!entrepreneur)}
                />
                Entrepreneur
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={investor}
                  value={investor}
                  onChange={(e) => setInvestor(!investor)}
                />
                Investor
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={student}
                  value={student}
                  onChange={(e) => setStudent(!student)}
                />
                Student
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={journalist}
                  value={journalist}
                  onChange={(e) => setJournalist(!journalist)}
                />
                Journalist
              </label>
              <input className="FormSubmit" type="submit" value="Submit" />
              <label className="FormLabel">
              </label>
            </form>
          </div>
          :
          <div className="HeroContainer">
            <div className="WaitlistHeaderContainer">
              <div className="WaitlistTitle">Join the ~ Movement ~</div>
              <div className="WaitlistSubtitle">We're building the first platform for decentralized work! Join the waitlist to get early access + early ownership.</div>
            </div>
            <div className="ToggleContainer">
              <button className="WaitlistToggleUnclicked" onClick={() => setForPeople(true)}>
                <p className="WaitlistToggleText">For People</p>
              </button>
              <button className="WaitlistToggleClicked">
                <p className="WaitlistToggleText">For Organizations</p>
              </button>
            </div>
            <form className="WaitlistForm" onSubmit={(e) => submitOrgSignup(e)}>
            <label className="FormLabel">
                What's your name or pseudonym? *
                <input 
                  className="FormInput"
                  type="text"
                  value={orgMemberName}
                  onChange={(e) => setOrgMemberName(e.target.value)}
                  required
                />
              </label>
              <label className="FormLabel">
                What's the name of your company or organization? *
                <input 
                  className="FormInput"
                  type="text"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  required
                />
              </label>
              <label className="FormLabel">
                Your position at the company or organization:
                <input 
                  className="FormInput"
                  type="text"
                  value={orgPosition}
                  onChange={(e) => setOrgPosition(e.target.value)}
                />
              </label>
              <label className="FormLabel">
                What email address can we use to contact you? *
                <input 
                  className="FormInput"
                  type="email"
                  value={orgEmail}
                  onChange={(e) => setOrgEmail(e.target.value)}
                  required
                />
              </label>
              <label className="FormLabel">
                Your company or organization's Twitter handle:
                <input 
                  className="FormInput"
                  type="text"
                  value={orgTwitter}
                  onChange={(e) => setOrgTwitter(e.target.value)}
                />
              </label>
              <label className="FormLabel">
                URL of your company or organization's website:
                <input 
                  className="FormInput"
                  type="text"
                  value={orgSite}
                  onChange={(e) => setOrgSite(e.target.value)}
                />
              </label>
              <label className="FormLabel">
                <div>How many team members do you have?</div>
                <input 
                  className="FormInputNumber"
                  type="number"
                  min={0}
                  step={1}
                  value={orgTeam}
                  onChange={(e) => setOrgTeam(e.target.value)}
                />
              </label>
              <label className="FormLabel">
                How many community members do you have?
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={smallSize}
                  value={smallSize}
                  onChange={(e) => handleSizeClick('small', e)}
                />
                0-100
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={mediumSize}
                  value={mediumSize}
                  onChange={(e) => handleSizeClick('medium', e)}
                />
                101-1000
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={largeSize}
                  value={largeSize}
                  onChange={(e) => handleSizeClick('large', e)}
                />
                1001-10000
              </label>
              <label className="FormLabelCheckbox">
                <input 
                  className="FormInputCheckbox"
                  type="checkbox"
                  checked={scaleSize}
                  value={scaleSize}
                  onChange={(e) => handleSizeClick('scale', e)}
                />
                >10000
              </label>
              <input className="FormSubmit" type="submit" value="Submit" />
              <label className="FormLabel">
              </label>
            </form>
          </div>
        )
        :
        <div className="HeroContainer">
          <img src={GlobeGif} alt="" className="GlobeGif" />
          <img src={Logo} className="MainLogo" alt="Subverse" />
          <button className="JoinWaitlistButton" onClick={openWaitlist}>
              <p className="JoinWaitlistText">Join Waitlist → Earn $XP</p>
          </button>
          <div className="ViewLeaderboardText">Already on the waitlist? Connect Metamask to sign in.</div>
        </div>
      )
    )
  )
}
