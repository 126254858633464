import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './App.css';
import Main from './components/Main';


const { createAlchemyWeb3 } = require("@alch/alchemy-web3");



function App() {
  
  return (
    <div className="app">
      <Router>
        <Main />
      </Router>
    </div>
  );
}

export default App;
