import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  connectWallet,
  getCurrentWalletConnected,
} from "../scripts/interact.js";
import TopLeftLogo from "../assets/SubverseGlobeLogo1.svg";
import Union from "../assets/Union.svg";
import Arrow1 from "../assets/Arrow1-cropped.svg";
import Arrow2 from "../assets/Arrow2-cropped.svg";
import homeIcon from "../assets/homeIcon.svg";
import buidlersIcon from "../assets/buidlersIcon.svg";
import communitiesIcon from "../assets/communitiesIcon.svg";
import opportunitiesIcon from "../assets/opportunitiesIcon.svg";
import profileIcon from "../assets/profileIcon.svg";
import magVector from "../assets/Vector.svg";
import menuArrow from "../assets/MenuArrow.svg";

import WaitlistLanding from "./WaitlistLanding";
import UnderConstruction from "./UnderConstruction";
import Profile from "./Profile";

// util for truncating wallet address string
function truncate(str) {
  return str != "Connect Metamask" && str.length >= 16
    ? str.substr(0, 6) + "..." + str.substr(str.length - 7, str.length - 1)
    : str;
}

export default function Main() {
  const navigate = useNavigate();
  const location = useLocation();

  // content for dropdowns
  const missionText1 =
    "Subverse is the home for self-sovereign web3 workers and their communities. Our mission is to highlight, connect, and match web3 workers, communities and organizations with each other.";
  const missionText2 =
    "Our vision is to promote the prosperity of individuals, projects and the web3 world as a whole through meaningful collaboration and good vibes.";
  const missionText3 = "wagmi (:";
  const buidlersText1 =
    "Subverse welcomes all self-sovereign workers from developers and engineers to designers and artists. In the world of blockchain, crypto and decentralized organizations, a traditional resume doesn't paint the full picture of an individual's value.";
  const buidlersText2 =
    "By using on-chain records, Subverse allows users to create verifiable resumes, showing a history of attendance, interactions and contributions made across various blockchains. Create and customize your profile to show off your true self and maximize your value.";
  const orgsText1 =
    "Subverse allows web3 communities, DAOs and companies to highlight themselves, their members, and exciting opportunities. Organizations can now be showcased as the living, breathing organisms they are.";
  const orgsText2 =
    "Display your ever-evolving org chart, new worker opportunities, community resources and more, all in one place. With Subverse, we make it easy to build, grow and maintain your oganization and community.";

  // state variable for main page open
  const [pageOpen, setPageOpen] = useState(true);

  //state variables for search text
  const [searchtext, setSearchtext] = useState("");
  // state variables for back/foward navigation
  const [hist, setHist] = useState(0);
  const [backind, setBackind] = useState(0);
  const [forind, setForind] = useState(0);

  const [instructions, setInstructions] = useState("↑ mouseclick ↑");

  // state variables for pages
  const [onHome, setOnHome] = useState("");
  const [onBuidlers, setOnBuidlers] = useState("");
  const [onCommunities, setOnCommunities] = useState("");
  const [onOpportunities, setOnOpportunities] = useState("");
  const [onProfile, setOnProfile] = useState("");

  const page_list = [
    onHome,
    onBuidlers,
    onCommunities,
    onOpportunities,
    onProfile,
  ];

  // state variables for metamask connection
  const [walletAddress, setWalletAddress] = useState("Connect Metamask");
  const [status, setStatus] = useState("not connected");

  // state variable for referral code
  const [referralCode, setReferralCode] = useState("");

  // state variables for dropdowns
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [missionDetails, setMissionDetails] = useState(false);
  const [buidlersDetails, setBuidlersDetails] = useState(false);
  const [orgsDetails, setOrgsDetails] = useState(false);
  const [contactDropdown, setContactDropdown] = useState(false);

  // decide what page you're on
  useEffect(() => {
    setSearchtext(selectSearchText());
    if (location.pathname == "/") {
      setOnHome("Selected");
      setOnBuidlers("");
      setOnCommunities("");
      setOnOpportunities("");
      setOnProfile("");
    } else if (location.pathname == "/buidlers") {
      setOnBuidlers("Selected");
      setOnHome("");
      setOnCommunities("");
      setOnOpportunities("");
      setOnProfile("");
    } else if (location.pathname == "/communities") {
      setOnCommunities("Selected");
      setOnHome("");
      setOnBuidlers("");
      setOnOpportunities("");
      setOnProfile("");
    } else if (location.pathname == "/opportunities") {
      setOnOpportunities("Selected");
      setOnHome("");
      setOnBuidlers("");
      setOnCommunities("");
      setOnProfile("");
    } else if (location.pathname == "/profile") {
      setOnProfile("Selected");
      setOnHome("");
      setOnBuidlers("");
      setOnCommunities("");
      setOnOpportunities("");
    } else {
      setReferralCode(location.pathname.substring(1));
      setOnHome("Selected");
      setOnBuidlers("");
      setOnCommunities("");
      setOnOpportunities("");
      setOnProfile("");
    }
  }, [location]);

  // get connected wallet info
  useEffect(() => {
    async function getWallet() {
      const { address, status } = await getCurrentWalletConnected();
      const addr = await setWalletAddress(address);
      setStatus(status);

      addWalletListener();
    }
    getWallet();
  }, []);

  //Returns correct search text
  function selectSearchText() {
    if (location.pathname == "/") {
      return "home/";
    } else if (location.pathname == "/buidlers") {
      return "buidlers/";
    } else if (location.pathname == "/communities") {
      return "communities/";
    } else if (location.pathname == "/opportunities") {
      return "opportunities/";
    } else if (location.pathname == "/profile") {
      return "profile/";
    } else {
      return "home/";
    }
  }

  const connectWalletPressed = async () => {
    closeDropdowns();
    if (walletAddress == "Connect Metamask") {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWalletAddress(walletResponse.address);
    } else {
      //copy wallet address
      navigator.clipboard.writeText(walletAddress);
      let addr = walletAddress;
      setWalletAddress("Copied!");
      // sleep, then change wallet address back
      await new Promise((r) => setTimeout(r, 1500));
      setWalletAddress(addr);
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setStatus("connected");
        } else {
          setWalletAddress("Connect Metamask");
          setStatus("not connected");
        }
      });
    } else {
      setStatus("not connected");
    }
  }

  async function closePage() {
    closeDropdowns();
    setPageOpen(false);
    await new Promise((r) => setTimeout(r, 2500));
    setInstructions("↓ spacebar ↓");
    await new Promise((r) => setTimeout(r, 2500));
    setInstructions("");
  }
  async function openPage() {
    setPageOpen(true);
    setInstructions("↑ mouseclick ↑");
  }

  // close all dropdowns
  async function closeDropdowns() {
    setAboutDropdown(false);
    setMissionDetails(false);
    setBuidlersDetails(false);
    setOrgsDetails(false);
    setContactDropdown(false);
  }

  // show/hide about dropdown when about link clicked
  async function toggleAboutDropdown() {
    setAboutDropdown(!aboutDropdown);
    setMissionDetails(false);
    setBuidlersDetails(false);
    setOrgsDetails(false);
    setContactDropdown(false);
  }

  // show/hide mission dropdown when menu item clicked
  async function toggleMissionDropdown() {
    setMissionDetails(!missionDetails);
    setBuidlersDetails(false);
    setOrgsDetails(false);
  }
  // show/hide buidlers dropdown when menu item clicked
  async function toggleBuidlersDropdown() {
    setBuidlersDetails(!buidlersDetails);
    setMissionDetails(false);
    setOrgsDetails(false);
  }
  // show/hide orgs dropdown when menu item clicked
  async function toggleOrgsDropdown() {
    setOrgsDetails(!orgsDetails);
    setBuidlersDetails(false);
    setMissionDetails(false);
  }

  // show/hide contact dropdown when about link clicked
  async function toggleContactDropdown(){
    setContactDropdown(!contactDropdown);
    setAboutDropdown(false);
    setMissionDetails(false);
    setBuidlersDetails(false);
    setOrgsDetails(false);
  }

  // handle a click on a div with a router link
  function divClick(address) {
    if (address != location.pathname) {
      setHist(backind + 1);
      setBackind(backind + 1);
      navigate(address);
    }
    closeDropdowns();
  }

  useEffect(() => {
    setForind(hist-backind);
  }, [backind])

  return pageOpen ? (
    <div className="app">
      <div className="HeaderBar">
        <div onClick={() => divClick("/")}>
          <img src={TopLeftLogo} alt="Subverse" className="TopLeftLogo" />
        </div>
        <div className="TopRightButton" onClick={closePage}>
          <img className="TopRightUnion" src={Union} alt="close" />
        </div>
      </div>
      <div className="HeaderNavRect">
        <div className="DocsLink">Docs</div>
        <div
          className={"AboutLink" + (aboutDropdown ? "Clicked" : "")}
          onClick={toggleAboutDropdown}
        >
          About
        </div>
        <a className="CommunityLink" href="https://discord.gg/DMd99W4tqE" target="blank">Community</a>
        <div
          className={"ContactLink" + (contactDropdown ? "Clicked" : "")}
          onClick={toggleContactDropdown}
        >
          Contact
        </div>
      </div>
      <div className={"AboutDropdown" + (aboutDropdown ? "" : "Hidden")}>
        <div className="AboutDropdownMenu">
          <div
            className={"DropdownMenuItem" + (missionDetails ? "Clicked" : "")}
            onClick={toggleMissionDropdown}
          >
            <div className="DropdownMenuText">Our Mission</div>
            <img className="MenuArrow" src={menuArrow}></img>
          </div>
          <div
            className={"DropdownMenuItem" + (buidlersDetails ? "Clicked" : "")}
            onClick={toggleBuidlersDropdown}
          >
            <div className="DropdownMenuText">For Buidlers</div>
            <img className="MenuArrow" src={menuArrow}></img>
          </div>
          <div
            className={"DropdownMenuItem" + (orgsDetails ? "Clicked" : "")}
            onClick={toggleOrgsDropdown}
          >
            <div className="DropdownMenuText">For Orgs</div>
            <img className="MenuArrow" src={menuArrow}></img>
          </div>
        </div>
      </div>
      <div className={"MissionDropdown" + (missionDetails ? "" : "Hidden")}>
        <div>
          <div className="DropdownSpacer"></div>
          <div className="MissionHeader">
            <div className="DropdownMenuText">Our Mission</div>
          </div>
        </div>
        <div className="MissionBody">
          <div className="DropdownMenuText">
            {missionText1}
            <br />
            <br />
            {missionText2}
            <br />
            <br />
            {missionText3}
          </div>
        </div>
      </div>
      <div className={"BuidlersDropdown" + (buidlersDetails ? "" : "Hidden")}>
        <div>
          <div className="DropdownSpacer"></div>
          <div className="BuidlersHeader">
            <div className="DropdownMenuText">For Buidlers</div>
          </div>
        </div>
        <div className="BuidlersBody">
          <div className="DropdownMenuText">
            {buidlersText1}
            <br />
            <br />
            {buidlersText2}
          </div>
        </div>
      </div>
      <div className={"OrgsDropdown" + (orgsDetails ? "" : "Hidden")}>
        <div>
          <div className="DropdownSpacer"></div>
          <div className="OrgsHeader">
            <div className="DropdownMenuText">For Organizations</div>
          </div>
        </div>
        <div className="OrgsBody">
          <div className="DropdownMenuText">
            {orgsText1}
            <br />
            <br />
            {orgsText2}
          </div>
        </div>
      </div>
      <div className={"ContactDropdown" + (contactDropdown ? "" : "Hidden")}>
        <div>
          <div className="ContactHeader">
            <div className="DropdownMenuText">( ﾟ▽ﾟ)/</div>
          </div>
        </div>
        <div className="ContactBody">
          <div className="DropdownMenuText">
            <a
              className="TwitterLink"
              href="https://twitter.com/SubverseWorld"
              target="_blank"
            >
              @SubverseWorld
            </a>
            <br />
            <br />
            rainford.eth
            <br />→{" "}
            <a
              className="TwitterLink"
              href="https://twitter.com/leozaroff"
              target="_blank"
            >
              @leozaroff
            </a>
            <br />
            <br />
            east9.eth
            <br />→{" "}
            <a
              className="TwitterLink"
              href="https://twitter.com/east9th"
              target="_blank"
            >
              @east9th
            </a>
          </div>
        </div>
      </div>
      <div className="MainNavbar">
        <div
          onClick={() => {
            console.log("back attempt. backind: " + backind+ " hist: "+hist);
            if (backind) {
              setBackind(backind - 1);
              navigate(-1);
            }
          }}
          className="HeaderButton"
          id="BackButton"
        >
          <img src={Arrow1} className="HeaderIcon" />
          <div className="HeaderText">Back</div>
        </div>
        <div
          onClick={() => {
            console.log("forward attempt. forind: " + forind+ " hist: "+hist);
            if (forind){
              setBackind(backind+1)
              navigate(1);
          }}}
          className="HeaderButton"
          id="ForwardButton"
        >
          <img src={Arrow2} className="HeaderIcon" />
          <div className="HeaderText">Forward</div>
        </div>
        <div
          //state={{ prevPath: location.pathname }}
          className={"HeaderButton" + onHome}
          onClick={() => divClick("/" + referralCode)}
        >
          <img src={homeIcon} className="HeaderIconMain" />
          <div className="HeaderText">Home</div>
        </div>
        <div
          //state={{ prevPath: location.pathname }}
          className={"HeaderButton" + onBuidlers}
          onClick={() => divClick("/buidlers")}
        >
          <img src={buidlersIcon} className="HeaderIconMain" />
          <div className="HeaderText">Buidlers</div>
        </div>
        <div
          //state={{ prevPath: location.pathname }}
          className={"HeaderButton" + onCommunities}
          onClick={() => divClick("/communities")}
        >
          <img src={communitiesIcon} className="HeaderIconMain" />
          <div className="HeaderText">Communities</div>
        </div>
        <div
          //state={{ prevPath: location.pathname }}
          className={"HeaderButton" + onOpportunities}
          onClick={() => divClick("/opportunities")}
        >
          <img src={opportunitiesIcon} className="HeaderIconMain" />
          <div className="HeaderText">Opportunities</div>
        </div>
        <div
          //state={{ prevPath: location.pathname }}
          className={"HeaderButton" + onProfile}
          onClick={() => divClick("/profile")}
        >
          <img src={profileIcon} className="HeaderIconMain" />
          <div className="HeaderText">Profile</div>
        </div>
        <div className="HeaderSpacer" />
        <div
          className="ConnectWalletButton"
          onClick={() => connectWalletPressed()}
        >
          <p className="ConnectWalletText">{truncate(walletAddress, 13)}</p>
        </div>
      </div>
      <div className="SearchBar">
        <div className="SearchButton">Search</div>
        <div className="SearchRectangle">
          <p className="SearchText">{searchtext}</p>
          <div className="MagButton">
            <img src={magVector} className="MagVector" />
          </div>
        </div>
      </div>
      <Routes>
        <Route
          path="/:id"
          element={
            <WaitlistLanding wallet={walletAddress} referral={referralCode} />
          }
        />
        <Route
          exact
          path="/"
          element={<WaitlistLanding wallet={walletAddress} referral="" />}
        />
        <Route exact path="/buidlers" element={<UnderConstruction />} />
        <Route exact path="/communities" element={<UnderConstruction />} />
        <Route exact path="/opportunities" element={<UnderConstruction />} />
        <Route exact path="/profile" element={<UnderConstruction />} />
      </Routes>
    </div>
  ) : (
    <div className="ClosedApp">
      <iframe
        className="DinoGame"
        src="https://mhasbini.com/miscs/react-chrome-dino-demo/index.html"
      />
      <div className="SpaceText">{instructions}</div>
      <div className="HeaderBarClosed" onClick={openPage}>
        <img src={TopLeftLogo} alt="Subverse" className="BottomLeftLogo" />
        <div className="TopRightButton">
          <img className="TopRightUnion" src={Union} alt="close" />
        </div>
      </div>
    </div>
  );
}
