import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import GlobeGif from "../assets/globe.gif";
import Logo from "../assets/largelogo.svg";
import Barrier from "../assets/Barrier.png";
import Cone from "../assets/Cone.png";

export default function UnderConstruction () {
  return (
    <div className="HeroContainer">
      <img src={Cone} alt="cone" className="ConstructionIcon" />
      <div className="Subtitle">This page is under construction... come back soon!</div>
    </div>
  )
}