/*
require('dotenv').config();
const alchemyKey = "";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
*/

// connect metamask
export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "connected",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        alert(err.message);
        return {
          address: "Connect Metamask",
          status: err.message,
        };
      }
    } else {
      alert("Error: Please install the Metamask browser extension and try again.");
      return {
        address: "Connect Metamask",
        status: "not connected"
      };
    }
  };

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "connected",
          };
        } else {
          return {
            address: "Connect Metamask",
            status: "not connected",
          };
        }
      } catch (err) {
        alert(err.message);
        return {
          address: "Connect Metamask",
          status: err.message,
        };
      }
    } else {
      return {
        address: "Connect Metamask",
        status: "not connected",
      };
    }
};